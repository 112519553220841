<template>
  <Layout :footer-style="1">
    <div class="container">
      <Breadcrumb root-url="/hardware-product" parent-url="硬件产品" current-url="存储" style="border: none;"/>
    </div>

    <div class="rn-service-area rn-section-gapBottom pt--100 bg-color-light area-2">
      <div class="container">
        <div class="product">
          <img class="pic" src="@/assets/images/product/n4.png" />
          <div class="about">
            <div class="title">存储</div>
            <div class="content">
              直营总代理,根据客户采购需求,灵活调配服务器,全品牌运营。
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Start Service Area -->
    <div class="rn-service-area rn-section-gapBottom pt--60 area-2">
      <div class="container">
        <div class="row" data-aos="fade-up">
          <div class="col-lg-12">
            <div class="section-title">
              <h3 class="title color-gray">华为</h3>
            </div>
          </div>
          <div class="col-lg-12 text-center">
            <img class="company-logo" src="@/assets/images/brand/brand-16.png">
            <div class="company-info">
              华为技术有限公司，成立于1987年，总部位于广东省深圳市龙岗区。华为是全球领先的信息与通信技术（ICT）解决方案供应商，专注于ICT领域，坚持稳健经营、持续创新、开放合作，在电信运营商、企业、终端和云计算等领域构筑了端到端的解决方案优势，为运营商客户、企业客户和消费者提供有竞争力的ICT解决方案、产品和服务，并致力于实现未来信息社会、构建更美好的全联接世界。            </div>
          </div>
        </div>

        <Separator style="margin: 80px 0;"/>

        <div class="row" data-aos="fade-up">
          <div class="col-lg-12">
            <div class="section-title">
              <h3 class="title color-gray">NetApp</h3>
            </div>
          </div>
          <div class="col-lg-12 text-center">
            <img class="company-logo" src="@/assets/images/brand/brand-22.png">
            <div class="company-info">
              NetApp不断以创新的理念和领先的技术引领存储行业的发展，是向数据密集型企业提供统一存储解决方案的居世界最前列的公司，其 Data ONTAP是全球首屈一指的存储操作系统。自 1992 年创立以来，NetApp一直在率先提供一流的技术、产品和合作关系，提升IT效率和灵活性的储存和数据管理解决方案，不断推动存储业的发展。NetApp 的存储解决方案涵盖了专业化的硬件、软件和服务，为开放网络环境提供了无缝的存储管理。            </div>
          </div>
        </div>

        <Separator style="margin: 80px 0;"/>

        <div class="row" data-aos="fade-up">
          <div class="col-lg-12">
            <div class="section-title">
              <h3 class="title color-gray">日立</h3>
            </div>
          </div>
          <div class="col-lg-12 text-center">
            <img class="company-logo" src="@/assets/images/brand/brand-23.png">
            <div class="company-info">
              日立环球存储科技公司创立于2003年，它是基于IBM和日立就存储科技业务进行战略性整合而创建的。存储业务是日立的五项核心业务之一，引进先进的技术及雄厚的资金是其业务发展及利润增长的重要因素。公司着眼于通过提供适用于办公室、途中以及家中的大容量存储产品使用户们全面感受数字化的生活方式。日立环球存储科技公司专注于迅速地发展硬盘驱动器的适用范围，使其跨越传统的计算机应用环境的界限，延伸至电子消费产品及其它新兴产品。
            </div>
          </div>
        </div>

        <Separator style="margin: 80px 0;"/>

        <div class="row" data-aos="fade-up">
          <div class="col-lg-12">
            <div class="section-title">
              <h3 class="title color-gray">EMC</h3>
            </div>
          </div>
          <div class="col-lg-12 text-center">
            <img class="company-logo" src="@/assets/images/brand/brand-24.png">
            <div class="company-info">
              EMC作为全球领导厂商，帮助企业和服务提供商实现运营转型，交付IT即服务。这一转型的基础是云计算。EMC通过创新的产品和服务，加速云计算之旅，帮助IT部门以更灵活、更可信和成本效益更高的方式，存储、管理、保护和分析他们的最宝贵的资产——信息。
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- End Service Area -->
  </Layout>
</template>

<script>
import Layout from '@/components/common/Layout'
import Icon from '@/components/icon/Icon'
import Separator from '@/components/elements/separator/Separator'
import SectionTitle from '@/components/elements/sectionTitle/SectionTitle'
import Breadcrumb from '@/components/elements/breadcrumb/Breadcrumb'
export default {
  name: 'Product',
  components: {
    SectionTitle,
    Separator,
    Icon,
    Breadcrumb,
    Layout
  },
  data() {
    return {
    }
  }
}
</script>
<style lang="scss" scoped>
.display-block{
  display: block;
}
.area-2{
  .icon {
    .p-img {
      width: 64px;
      height: 64px;
    }
  }
  .more{
    display: inline-flex;
    color: #2261AA;
    img {
      width: 27px;
      height: 27px;
    }
    .i1{
      margin-left: -4px;
    }
    .i2{
      margin-left: -20px;
    }
  }
}
.product{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  .pic{
    height: 220px;
  }
  .about{
    margin-left: 40px;
    color: #000000;
    .title{
      font-size: 32px;
      font-weight: bold;
    }
    .content{
      margin-top: 12px;
      font-size: 20px;
    }
  }
}
.company-logo{
  width: 320px;
}
.company-info{
  font-size: 24px;
  color: #000000;
  text-align: left;
  margin-top: 20px;
}
</style>
